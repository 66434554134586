import './App.css';
import Logo from './assets/logo_white.png'
import { FiFacebook, FiInstagram, FiPhone } from 'react-icons/fi'
import { AiOutlineWhatsApp } from 'react-icons/ai'

function App() {
  return (
    <div className="min-h-screen p-10 text-[#cba475] bg-black lg:px-32">
      <h1 className='text-center uppercase'> Site Under Construction</h1>
      <img src={Logo} alt="logo" className='w-96 ml-auto mr-auto'/>
      <div className='flex flex-col'>
        <h1 className='text-[#cba475] text-2xl md:text-4xl tracking-widest font-medium mb-2'>DR. OSCAR HUERTA GARCÍA DE LEÓN</h1>
        <h2 className='uppercase text-[#cba475] md:text-xl text-basis tracking-widest mb-1 font-extralight' >Cirujano plástico Certificado</h2>
        <h3 className='font-extralight tracking-widest text-[#cba475]'>No. 2266</h3>
      </div>
      <div className='flex gap-5 text-3xl md:text-4xl md:gap-10 justify-center mt-10 text-[#cba475]'>
        <a href='https://www.instagram.com/oh.plasticsurgery/' target='_blank' rel='noreferrer' className='hover:bg-[#cba475] hover:duration-200 hover:text-white bg-black rounded-full p-2'><FiInstagram /> </a>
        <a href='https://www.facebook.com/ohplasticsurgery' target='_blank' rel='noreferrer' className='hover:bg-[#cba475] hover:duration-200  hover:text-white bg-black rounded-full p-2'><FiFacebook /></a>
        <a href='https://wa.me/524431561206' target='_blank' rel='noreferrer' className='hover:bg-[#cba475] hover:duration-200  hover:text-white bg-black rounded-full p-2'><AiOutlineWhatsApp /> </a>
        <a href='tel:+524431561206' target='_blank' rel='noreferrer' className='hover:bg-[#cba475] hover:duration-200 hover:text-white bg-black rounded-full p-2'><FiPhone /> </a>        
      </div>
    </div>
  );
}

export default App;
